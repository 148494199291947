import React, { useState, useEffect, useContext } from "react";
import {Form} from "react-bootstrap";
import NotyfContext from "../contexts/NotyfContext";
import { Eye, CheckCircle, XCircle, StopCircle, Clock, UserCheck } from "react-feather";
import { Table, Button, Modal} from "react-bootstrap";

import EditableExpires from "./EditableExpires";
import EditableExpiresControls from "./EditableExpiresControls";
import CopyToClipboardToggle from "./CopyToClipboardToggle";

import authenticatedFetch from "../utils/fetch"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHospitalAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  faBuilding
} from "@fortawesome/free-regular-svg-icons";

const rawPrivilegesTypes =[
    {"value":"", "label":""},
    {"value":"ACTIVE", "label":"Active"},
    {"value": "APP", "label":"APP"},
    {"value": "CONSULTING", "label":"Consulting"},
    {"value":"COURTESY", "label":"Courtesy"},
    {"value": "EXPIRED", "label":"Expired"},
    {"value": "OTHER", "label":"Other"},
    {"value": "TEMPORARY", "label":"Temporary"},
    {"value": "VOLUNTARILY_RESIGNED", "label":"Voluntarily Resigned"}
  ];

const FacilityContactInfoModal = ({ show, onClose, facilityName, address1, address2, city, state, zip, phone, email, fax, hours, notes}) => {

  var copyFormatted = facilityName;
  copyFormatted += (address1 != null ? ("\n" + address1) : "")
  copyFormatted += (address2 != null ? ("\n" + address2) : "")
  copyFormatted += "\n" + (city != null ? city : "") + (state != null ? (", " + state) : "") + (zip != null ? (" " + zip) : "");
  copyFormatted += (phone != null ? ("\n" + phone) : "");
  copyFormatted += (fax != null ? ("\n" + fax) : "");
  copyFormatted += (email != null ? ("\n" + email) : "");
  copyFormatted += (hours != null ? ("\n" + hours) : "");
  copyFormatted += (notes != null ? ("\n" + notes) : "");

  const onCopyValue = () => {
    // No-op for now
  }

  return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Contact Info for: <b>{facilityName}</b></span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className="table-borderless"> 
            <tbody>
              <tr>
                <td>
                  <b>Address:</b>
                </td>
                <td>
                {address1}
                <br />
                {address2 != null
                ?
                <>{address2}
                <br />
                </>
                : <></>
                }
                {city}{city != null ?", " : ""}{state} {zip}
                </td>
              </tr>
              <tr>
                <td><b>Phone:</b></td>
                <td>{phone}</td>
              </tr>
              <tr>
                <td><b>Fax:</b></td>
                <td>{fax}</td>
              </tr>
              <tr>
                <td><b>Email:</b></td>
                <td>{email}</td>
              </tr>
              <tr>
                <td><b>Hours:</b></td>
                <td>{hours}</td>
              </tr>
              <tr>
                <td><b>Notes:</b></td>
                <td>{notes}</td>
              </tr>
            </tbody>
          </Table>
          <span className="float-end"><CopyToClipboardToggle text={copyFormatted} onCopyValue={onCopyValue}/></span>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
          <Button onClick={onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
  );
}



const FacilityParticipation = ({ onSave, facilityId, entityId, entityType, entityName, facilityName, phone, fax, email, hours, notes, facilityType, address1, address2, city, state, zip, expires, effectiveDate, privilegesType, portal, active, primary, pending, canEdit, showInactive=false}) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
  
    if (privilegesType === null)
    {
        privilegesType = '';   
    }

    const [editingExpires, setEditingExpires] = useState(false);
    const [localExpires, setLocalExpires] = useState(expires);
    const [localEffectiveDate, setLocalEffectiveDate] = useState(effectiveDate);
    const [localPrivilegesType, setLocalPrivilegesType] = useState(privilegesType);
    const [localActive, setLocalActive] = useState(active);
    const [localPrimary, setLocalPrimary] = useState(primary);
    
    const notyf = useContext(NotyfContext);

    useEffect(() => {
      setLocalActive(active);
      setLocalPrimary(primary);
      setLocalExpires(expires);
      setLocalEffectiveDate(effectiveDate);
      setLocalPrivilegesType(privilegesType); // TODO this is from early on - not sure we need this in useEffect
      setEditingExpires(false);
    }, [facilityId, entityType, entityId, active, expires]);
  
    const handleEditExpiresClicked = (e) =>
    {
      e.preventDefault();
      setEditingExpires(true);
    }
  
    const saveExpires = (e) =>
    {
      e.preventDefault();
      setEditingExpires(false);
  
      authenticatedFetch({
        path: entityType + "/participationstatus/facility/" + entityId + "/" + facilityId,
        method: "POST",
        requestData: {active: localActive, primary: localPrimary, expiration: localExpires, effectiveDate: localEffectiveDate, privilegesType: localPrivilegesType},
        successHandler: function() {
          onSave(); // easiest way to update expires (so undo works the next time) is just to have parent re-render us - TODO this is from early on 
  
          notyf.open({type: "success", message: "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
        },
        errorHandler: function() {
          setLocalActive(active);
          setLocalPrimary(primary);
          setLocalExpires(expires);
          setLocalEffectiveDate(effectiveDate);
          alert("Something went wrong; please try again");
        }
      });
    }
  
    const cancelEditExpires = (e) => {
      e.preventDefault();
      setEditingExpires(false);
      setLocalActive(active);
      setLocalPrimary(primary);
      setLocalExpires(expires);
      setLocalEffectiveDate(effectiveDate);
      setLocalPrivilegesType(privilegesType);
    }
  
    const handleActiveChanged = (e) => {
      // Prevent default here actually prevents the box from checking/unchecking
  
      if (!e.target.checked)
      {
        // Make it clear that we're wiping expires too (since server actually deletes this row)
        setLocalPrimary(false);
        setLocalExpires(null);
        setLocalEffectiveDate(null);
        setLocalPrivilegesType(null);
      }
  
      setLocalActive(e.target.checked);
    }
  
    const handlePrimaryChanged = (e) => {
      setLocalPrimary(e.target.checked);
    }

    const handleExpiresChanged = (date, e) => {
      e.preventDefault();
    
      // If they clear it out, date picker sends it back as "", not null
      if (date === "")
      {
        date = null;
      }
  
      setLocalExpires(date);
    }
  
    const handleEffectiveDateChanged = (date, e) => {
      e.preventDefault();
    
      // If they clear it out, date picker sends it back as "", not null
      if (date === "")
      {
        date = null;
      }
  
      setLocalEffectiveDate(date);
    }

    const handlePrivilegesTypeChanged = (e) => {
        e.preventDefault();

        setLocalPrivilegesType(e.target.value);
    }
  

    let privilegesTypeList = rawPrivilegesTypes.map((privilegesType, i) => {
        return (
          <option key={i} value={privilegesType.value}>{privilegesType.label}</option>
        )
      }, this);
  
    var selectedPrivilegesTypeLabel = '';
    if (localPrivilegesType !== '')
    {
        for (let i = 0; i < rawPrivilegesTypes.length; i++)
        {
            if (localPrivilegesType === rawPrivilegesTypes[i].value)
            {
                selectedPrivilegesTypeLabel = rawPrivilegesTypes[i].label;
                break;
            }
        }
    }

    let showLocation = (active && !(privilegesType === 'VOLUNTARILY_RESIGNED' || privilegesType === 'EXPIRED')) || pending || showInactive;
 
    return (
      <tr style={{display: showLocation ? "" : "none"}}>
          <td>
            {facilityType === 'CLINIC' ?
            <FontAwesomeIcon icon={faBuilding} size="lg" style={{color: '#2B7A78'}}/>
            :
            <FontAwesomeIcon icon={faHospitalAlt} size="lg" style={{color: '#2B7A78'}}/>
            }
          </td>
          <td style={{maxWidth: '160px', paddingRight: '0'}}>
            {facilityName}
          </td>
          <td><Eye style={{cursor: 'pointer'}}  onClick={(e) => handleShow()}/></td>
          <td>
          {editingExpires && canEdit
          ? 
          <Form.Check checked={localActive} onChange={(e) => {handleActiveChanged(e)}} />  
          :  
          pending ? <Clock className="text-warning" /> : (localActive ? ((privilegesType === 'VOLUNTARILY_RESIGNED' || privilegesType === 'EXPIRED') ? <StopCircle/> : <CheckCircle className="text-success" />) : <XCircle className="text-danger" />)
          }
          </td>
          <td>
          {editingExpires && canEdit && localActive
          ? 
          <Form.Check checked={localPrimary} onChange={(e) => {handlePrimaryChanged(e)}} />  
          :  
          (localPrimary ? <UserCheck className="text-success" /> : <></>)
          }
          </td>
          <td>
            <EditableExpires warnWithColors={false} localExpires={localEffectiveDate} localActive={localActive} editingExpires={editingExpires} handleExpiresChanged={handleEffectiveDateChanged} />
          </td>
          <td>
            <EditableExpires localExpires={localExpires} localActive={localActive} editingExpires={editingExpires} handleExpiresChanged={handleExpiresChanged} />
          </td>
          <td>
            {editingExpires && canEdit && localActive ?
            <Form.Select size="sm" value={localPrivilegesType} onChange={(e) => {handlePrivilegesTypeChanged(e)}} style={{width: "auto"}} >
                {privilegesTypeList}
            </Form.Select>
            :
            <>{selectedPrivilegesTypeLabel}</>
            }
          </td>
          <td>
            {canEdit ?
              <EditableExpiresControls editingExpires={editingExpires} cancelEditExpires={cancelEditExpires} saveExpires={saveExpires} handleEditExpiresClicked={handleEditExpiresClicked} />
            : <>&nbsp;</>
            }
          </td>
          <FacilityContactInfoModal show={show} onClose={handleClose} facilityName={facilityName} address1={address1} address2={address2} city={city} state={state} zip={zip} phone={phone} fax={fax} email={email} hours={hours} notes={notes} />
      
      </tr>
    );
  }

  export default FacilityParticipation;
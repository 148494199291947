import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Card } from "react-bootstrap";

import Header from "./Header";
import Feed from "./Feed";
import Statistics from "./Statistics";

import { ExternalLink } from "react-feather";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";

import authenticatedFetch from "../../../utils/fetch"
import { useSearchParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClinicMedical } from "@fortawesome/free-solid-svg-icons";

const PracticeCard = ({ name, id, size, tin, phone, fax, officeHours, shortCode }) => (
  <Card style={{width: '100%'}}>
    <Card.Header style={{marginBottom: '-25px'}}>
        <Card.Title className="mb-0 align-middle">
          <FontAwesomeIcon icon={faClinicMedical} style={{color: '#2B7A78'}} />
          &nbsp;<a style={{color: '#17252A'}} href={'/practice/' + id}>{name}</a>
        </Card.Title>
        {officeHours}
    </Card.Header>
    <Card.Body>
      <Card.Text>
        {size} Physician{size === 0 || size > 1 ? "s" : ""} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br />
        TIN: {tin}<br />
        P: {phone}<br />
        F: {fax}
      </Card.Text>
      {shortCode && <Card.Link href={"/network/" + shortCode} target="_blank"><ExternalLink style={{'height': '16px', 'width': '16px'}}/> Network Tool</Card.Link>}
    </Card.Body>
  </Card>
);

const Default = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [searchParams] = useSearchParams();
  
  const { isH3Admin, authorities } = useContext(UserSettingsContext);

  useEffect(() => {
      if (searchParams.get("destination"))
      {
        window.location = decodeURIComponent(searchParams.get("destination"));
        return;
      }
      
      if (isH3Admin || authorities.includes("PRACTICE_BASIC_VIEW") || authorities.includes("PRACTICE_BASIC_EDIT"))
      {
        authenticatedFetch({
          path: "practice/revenueteam",
          successHandler: function(result) {
            setIsLoaded(true);
            setData(result);
          },
          errorHandler: function(error) {
            setIsLoaded(true);
            setError(error);
          }
        });
      }
  }, []);

  if (isH3Admin || authorities.includes("PRACTICE_BASIC_VIEW") || authorities.includes("PRACTICE_BASIC_EDIT"))
  {
    var practiceList;
    if (error)
    {
      practiceList = <div>Error... please try again...</div>;
    }
    else if (!isLoaded)
    {
      practiceList = <div>Loading...</div>;
    }
    else if (!data || data.length === 0)
    {
      practiceList = <div>No practices</div>;
    }
    else 
    {
      // TODO figure out less ugly way to build this conditionally
      practiceList = data.map((practice, index, elements) => {
        if (index % 2 === 0)
        {
          if (elements.length > index + 1) {
            return (
              <Row key={elements[index].id}>
                <Col className="d-flex">
                  <PracticeCard size={elements[index].size} tin={elements[index].tin} name={elements[index].name} id={elements[index].id} phone={elements[index].phone1} fax={elements[index].fax} officeHours={elements[index].officeHours} shortCode={elements[index].shortCode}/>
                </Col>
                <Col className="d-flex">
                  <PracticeCard size={elements[index+1].size} tin={elements[index+1].tin} name={elements[index + 1].name} id={elements[index + 1].id} phone={elements[index + 1].phone1} fax={elements[index + 1].fax} officeHours={elements[index + 1].officeHours} shortCode={elements[index + 1].shortCode}/>
                </Col>
              </Row>
            );
          } else {
            return (
              <Row key={elements[index].id}>
                <Col className="d-flex">
                  <PracticeCard size={elements[index].size} tin={elements[index].tin} name={elements[index].name} id={elements[index].id} phone={elements[index].phone1} fax={elements[index].fax} officeHours={elements[index].officeHours} shortCode={elements[index].shortCode}/>
                </Col>
                <Col />
              </Row>
            );
          }
        }
      }, this);
    }
  }

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Container fluid className="p-0">
        <Header />
        <Statistics />
        <Row>
        {(isH3Admin || authorities.includes("PRACTICE_BASIC_VIEW") || authorities.includes("PRACTICE_BASIC_EDIT")) ?
          <Col lg="8">
            <h6>Practices</h6><br />
            {practiceList}
          </Col>
          : <></>
          }
          {(isH3Admin || authorities.includes("TASK_VIEW") || authorities.includes("TASK_EDIT")) ?
          <Col lg="4" className="d-flex">
            <Feed />
          </Col>
          : <></>
          }
        </Row>
      </Container>
    </React.Fragment>
  )
};

export default Default;

import React, {useEffect, useState} from "react";
import { Form, Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserNurse,
  faBriefcaseMedical,
  faRunning
} from "@fortawesome/free-solid-svg-icons";

const { REACT_APP_BACKEND_URL } = process.env;

const CarrierSelect = ({ token, selectedCarrierId, handleCarrierChanged}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (token != null && token !== "")
    {
      fetch(REACT_APP_BACKEND_URL + "network/practice/" + token + "/carriers")
      .then(res => {
          if (res.ok)
          {
              return res.json();
          }
    
          throw Error(res.status);
      })
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    }
    else
    {
      setIsLoaded(false);
      setData([]);
    }
  }, [token])


  if (!isLoaded)
  {
    return (<Form.Select style={{height: "36px"}} disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select style={{height: "36px"}} disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
    let carrierOptionList = [<option value={0} key={0}>Select insurance carrier...</option>];
    carrierOptionList.push(data.map((carrier, i) => {
      return (
        <option value={carrier.id} key={carrier.id} data-has-business-lines={carrier.hasBusinessLines} data-is-out-of-network={carrier.isOutOfNetwork}>{carrier.name}</option>
      )
    }, this));

    return (
      <Form.Select style={{height: "36px"}} onChange={(e) => {handleCarrierChanged(e)}} value={selectedCarrierId}>{carrierOptionList}</Form.Select>
    )
  }
}

const BusinessLineSelect = ({ token, carrierId, selectedBusinessLineId, handleBusinessLineChanged}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (token != null && token !== "" && carrierId !== 0)
    {
      fetch(REACT_APP_BACKEND_URL + "network/practice/" + token + "/carrier/" + carrierId + "/businesslines")
      .then(res => {
          if (res.ok)
          {
              return res.json();
          }
    
          throw Error(res.status);
      })
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    }
    else
    {
      setIsLoaded(false);
      setData([]);
    }
  }, [token, carrierId])


  if (carrierId == null || carrierId === 0)
  {
    return (<Form.Select style={{height: "36px"}} disabled={true}><option value={0} key={0}>Please first select an insurance carrier...</option></Form.Select>);
  }
  else if (!isLoaded)
  {
    return (<Form.Select style={{height: "36px"}} disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select style={{height: "36px"}} disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
    let businessLineOptionList = [<option value={0} key={0}>Select plan...</option>];
    businessLineOptionList.push(data.map((bl, i) => {
      return (
        <option value={bl.id} key={bl.id}>{bl.name}</option>
      )
    }, this));

    return (
      <Form.Select onChange={(e) => {handleBusinessLineChanged(e)}} value={selectedBusinessLineId}>{businessLineOptionList}</Form.Select>
    )
  }
}

function containsAllWords(words, name)
{
  for (var i=0; i < words.length; i++)
  {
    if(!name || name.toLowerCase().indexOf(words[i].trim().toLowerCase()) < 0)
    {
      return false;
    }
  }

  return true;
}

const CarrierAndBusinessLineSelect = ({ token, handleCarrierAndBusinessLineChanged, selectedCarrierAndBusinessLine}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (token != null && token !== "")
    {
      fetch(REACT_APP_BACKEND_URL + "network/practice/" + token + "/carriersandbusinesslines")
      .then(res => {
          if (res.ok)
          {
              return res.json();
          }

          throw Error(res.status);
      })
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    }
    else
    {
      setIsLoaded(false);
      setData([]);
    }
  }, [token])

  const options = data.map((plan) => {
      return {value: plan.carrierId + ":" + plan.businessLineId, label: plan.name, carrierId: plan.carrierId, businessLineId: plan.businessLineId, isOutOfNetwork: plan.isOutOfNetwork};
  });

  if (!isLoaded)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
      const customFilter = (option, searchText) => {
        let words = searchText.trim().split(" ");
        return containsAllWords(words, option.data.label);
      };

      return (
        <Select
        options={options}
        filterOption={customFilter}
        onChange={(selectedCarrierAndBusinessLine) => {
          handleCarrierAndBusinessLineChanged(selectedCarrierAndBusinessLine);
        }}
        value={selectedCarrierAndBusinessLine}
        placeholder="Search for insurance carrier or plan..."
        isClearable={true}
        backspaceRemovesValue={true}

        theme={(theme) => ({
          ...theme,
          colors: {
          ...theme.colors,
            primary50: '#DEF2F1',
            primary25: '#DEF2F1',
            primary: '#3AAFA9',
          },
        })}
        />
      )
  }
}


const ProviderList = ({ token, businessLineId, carrierId, carrierHasBusinessLines}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (token != null && token !== "" && (businessLineId > 0 || !carrierHasBusinessLines))
    {
      fetch(REACT_APP_BACKEND_URL + "network/practice/" + token + "/carrier/" + carrierId + "/businessline/" + businessLineId)
      .then(res => {
          if (res.ok)
          {
              return res.json();
          }
    
          throw Error(res.status);
      })
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    }
    else
    {
      setIsLoaded(false);
      setData([]);
    }
  }, [token, businessLineId, carrierId, carrierHasBusinessLines])


  if (carrierHasBusinessLines && (businessLineId == null || businessLineId <= 0))
  {
    return (<span>Please first select an insurance carrier and plan...</span>);
  }
  else if (!isLoaded)
  {
    return (<span>Loading...</span>);
  }
  else if (error || !data)
  {
    return (<span>Error... Please reload the page...</span>);
  }
  else
  {
    let providerList = data.map((provider, i) => {
      let locationlist = provider.locations.map((location, i) => {
        return (
          <tr key={i}>
            <td>{location.name}</td>
            <td>{location.city}</td>
            <td>{location.state}</td>
          </tr>
        )
      }, this);

      var icon = faRunning;
      if (provider.isPhysician)
      {
        icon = faBriefcaseMedical;
      }
      else if (provider.isApp)
      {
        icon = faUserNurse
      }

      return (
        <tr key={i}>
          <td><FontAwesomeIcon icon={icon} style={{height: "16px", width: "16px", color: "#2B7A78"}} />&nbsp;&nbsp;<b>{provider.lastName}, {provider.firstName} {provider.credentials}</b></td>
          <td>
            <Table striped className="table-sm">
              <thead>
                <tr>
                  <td>Name</td>
                  <td>City</td>
                  <td>State</td>
                </tr>
              </thead>
              <tbody>
                {locationlist}
              </tbody>
            </Table>

          </td>
        </tr>
      )
    }, this);

    if (providerList.length === 0)
    {
      return (
        <span>No active providers found</span>
      );
    }

    return (
      <Table hover className="table-sm">
      <thead>
        <tr>
          <td><b>Provider</b></td>
          <td><b>Locations</b></td>
        </tr>
      </thead>
      <tbody>
        {providerList}   
      </tbody>
    </Table>
    );
  }
}

const DropdownNetworkLookup = ({token}) => {
  const[selectedCarrierId, setSelectedCarrierId] = useState(0);
  const[carrierHasBusinessLines, setCarrierHasBusinessLines] = useState(false);
  const[carrierIsOutOfNetwork, setCarrierIsOutOfNetwork] = useState(false);
  const[selectedBusinessLineId, setSelectedBusinessLineId] = useState(0);

  const handleCarrierChanged = (e) => {
    e.preventDefault();
    setSelectedCarrierId(parseInt(e.target.value));
    setCarrierHasBusinessLines(e.target.options[e.target.selectedIndex].getAttribute("data-has-business-lines") === "true");
    setCarrierIsOutOfNetwork(e.target.options[e.target.selectedIndex].getAttribute("data-is-out-of-network") === "true");
    setSelectedBusinessLineId(0);
  }

  const handleBusinessLineChanged = (e) => {
    e.preventDefault();
    setSelectedBusinessLineId(parseInt(e.target.value));
  }

  return (
    <>
      <Table className="table-borderless table-sm">
        <tbody>
          <tr>
            <td> <CarrierSelect token={token} selectedCarrierId={selectedCarrierId} handleCarrierChanged={handleCarrierChanged} /></td>
          </tr>
        {
           carrierIsOutOfNetwork && 
           <tr>
            <td colSpan={2}>
              <br />
              Please verify patient's out of network benefits
            </td>
          </tr>
        }
        { !carrierIsOutOfNetwork && selectedCarrierId !== 0 && carrierHasBusinessLines &&
          <tr>
            <td><BusinessLineSelect token={token} carrierId={selectedCarrierId} selectedBusinessLineId={selectedBusinessLineId} handleBusinessLineChanged={handleBusinessLineChanged} /></td>
          </tr>
        }
        { !carrierIsOutOfNetwork && selectedCarrierId !== 0 && !carrierHasBusinessLines &&
          <tr>
            <td colSpan={2}>
              <br />
              <ProviderList token={token} carrierId={selectedCarrierId} businessLineId={selectedBusinessLineId} carrierHasBusinessLines={carrierHasBusinessLines} />
            </td>
          </tr>
        }
        </tbody>
      </Table>
      <Table>
        <tbody>
        { selectedBusinessLineId > 0 &&
        <tr>
          <td colSpan={2}>
            <br />
            <ProviderList token={token} carrierId={selectedCarrierId} businessLineId={selectedBusinessLineId} carrierHasBusinessLines={carrierHasBusinessLines} />
          </td>
        </tr>
        }
        </tbody>
      </Table>
    </>

  );
}

const SearchNetworkLookup = ({token}) => {
  const[selectedCarrierAndBusinessLine, setSelectedCarrierAndBusinessLine] = useState("");
  const[selectedCarrierId, setSelectedCarrierId] = useState(0);
  const[selectedBusinessLineId, setSelectedBusinessLineId] = useState(0);
  const[carrierIsOutOfNetwork, setCarrierIsOutOfNetwork] = useState(false);

  const handleCarrierAndBusinessLineChanged = (selectedCarrierAndBusinessLine) => {
    if (selectedCarrierAndBusinessLine == null)
    {
      setSelectedCarrierAndBusinessLine("");
      setSelectedCarrierId(0);
      setSelectedBusinessLineId(0);
      setCarrierIsOutOfNetwork(false);
    }
    else
    {
      setSelectedCarrierAndBusinessLine(selectedCarrierAndBusinessLine);
      setSelectedCarrierId(parseInt(selectedCarrierAndBusinessLine.carrierId));
      setSelectedBusinessLineId(parseInt(selectedCarrierAndBusinessLine.businessLineId));
      setCarrierIsOutOfNetwork(selectedCarrierAndBusinessLine.isOutOfNetwork);
    }
  }

  return (
    <>
      <Table className="table-borderless table-sm">
        <tbody>
          <tr>
            <td> <CarrierAndBusinessLineSelect token={token} handleCarrierAndBusinessLineChanged={handleCarrierAndBusinessLineChanged} selectedCarrierAndBusinessLine={selectedCarrierAndBusinessLine} /></td>
          </tr>
        </tbody>
      </Table>
      <Table>
        <tbody>
        {
           carrierIsOutOfNetwork && selectedCarrierId > 0 &&
           <tr>
            <td colSpan={2}>
              <br />
              Please verify patient's out of network benefits
            </td>
          </tr>
        }
        { !carrierIsOutOfNetwork && selectedCarrierId > 0 &&
        <tr>
          <td colSpan={2}>
            <br />
            <ProviderList token={token} carrierId={selectedCarrierId} businessLineId={selectedBusinessLineId} carrierHasBusinessLines={selectedBusinessLineId > 0} />
          </td>
        </tr>
        }
        </tbody>
      </Table>
    </>

  );
}

const NetworkLookup = ({token}) => {
  const [searchParams] = useSearchParams();

  if (searchParams.get("version") === "search")
  {
    return <SearchNetworkLookup token={token} />
  }

  return <DropdownNetworkLookup token={token} />
};


export default NetworkLookup;

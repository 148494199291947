import doctor1 from "../assets/img/avatars/logo_unknown_doctor.jpg";

const caseInsensitiveSort = ({ row1, row2, columnName }) => {
    const rowOneColumn = row1.values[columnName];
    const rowTwoColumn = row2.values[columnName];

    if (rowOneColumn === null || rowOneColumn === undefined) {
        return -1;
    }

    if (rowTwoColumn === null || rowTwoColumn === undefined) {
        return 1;
    }

    if (isNaN(rowOneColumn)) {
        return rowOneColumn.toUpperCase() >
            rowTwoColumn.toUpperCase()
            ? 1
            : -1;
    }
    return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
};

export { caseInsensitiveSort };


const getAssigneeAvatarDisplay = (assignees) => {
    if (assignees && assignees.length > 0) {
      return assignees.map((assignee, i) => {
        return (
          <img
            key={i}
            src={assignee.photoUrl == null || assignee.photoUrl === '' ? doctor1 : assignee.photoUrl}
            width="20"
            height="20"
            className="rounded-circle"
            alt={assignee.firstName + ' ' + assignee.lastName}
            title={assignee.firstName + ' ' + assignee.lastName}
          />
        )
      }, this);
    }
  
    return "";
  }

export { getAssigneeAvatarDisplay };
import React, { useState, useEffect, useContext } from "react";
import { Table, Button, Modal, Form, Card, Spinner} from "react-bootstrap";
import NotyfContext from "../contexts/NotyfContext";
import authenticatedFetch from "./fetch"

import getActivityDateTimeDisplay from "./datetime"

const BusinessLineSelect = ({ practiceId, carrierId, selectedBusinessLineId, handleBusinessLineChanged}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (practiceId > 0 && carrierId > 0)
    {
      authenticatedFetch({
        path: "businessline/practice/" + practiceId + "/carrier/" + carrierId,
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
        },
        errorHandler: function(error) {
          setIsLoaded(true);
          setError(error);
        }
      });
    }
    else
    {
      setIsLoaded(false);
      setData([]);
    }
  }, [practiceId, carrierId])


  if (!isLoaded)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Loading...</option></Form.Select>);
  }
  else if (error || !data)
  {
    return (<Form.Select disabled={true}><option value={0} key={0}>Error... Please reload the page...</option></Form.Select>);
  }
  else
  {
    let businessLineOptionList = [<option value={0} key={0}>Select a plan...</option>];
    businessLineOptionList.push(data.map((bl, i) => {
      return (
        <option value={bl.id} key={bl.id}>{bl.name}</option>
      )
    }, this));

    return (
      <Form.Select onChange={(e) => {handleBusinessLineChanged(e)}} value={selectedBusinessLineId}>{businessLineOptionList}</Form.Select>
    )
  }
}

const FacilityRow = ({ practiceId, providerId, businessLineId, facilityId, facilityName, isActive}) => {
  const [localIsActive, setLocalIsActive] = useState(isActive);

  const notyf = useContext(NotyfContext);

  const handleActiveChanged = (e) => {
    setLocalIsActive(e.target.checked);

    authenticatedFetch({
      method: "PUT",
      requestData: {isActive: e.target.checked},
      path: "businessline/practice/" + practiceId + "/provider/" + providerId + "/businessline/" + businessLineId + "/facility/" + facilityId,
      successHandler: function(result) {
        notyf.open({type: "success", message: "Saved!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setLocalIsActive(!e.target.checked)
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });


  }

  return (
    <tr>
      <td><Form.Check value={facilityId} checked={localIsActive} onChange={(e) => {handleActiveChanged(e)}}/></td>
      <td>{facilityName}</td>
    </tr>
  )
}


const FacilityList = ({ isLoaded, error, data, practiceId, providerId, businessLineId}) => {
  if (!isLoaded)
  {
    return (
      <Card style={{marginTop:"10px", minHeight: "200px"}}>
        <Card.Header><Card.Title style={{fontSize: "1em"}}>Locations</Card.Title></Card.Header>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th>Loading...</th>
            </tr>
          </thead>
        </Table>
      </Card>
    )
  }
  else if (error || !data)
  {
    return (
      <Card style={{marginTop:"10px", minHeight: "200px"}}>
        <Card.Header><Card.Title style={{fontSize: "1em"}}>Locations</Card.Title></Card.Header>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th>Error... Please reload the page...</th>
            </tr>
          </thead>
        </Table>
      </Card>
    )
  }
  else
  {
    let facilityOptionList = data.map((facility, i) => {
      return (
        <FacilityRow key={i} practiceId={practiceId} providerId={providerId} businessLineId={businessLineId} facilityId={facility.id} facilityName={facility.name} isActive={facility.isActive} />
      )
    }, this);

    return (
      <Card style={{marginTop:"10px", minHeight: "200px"}}>
        <Card.Header><Card.Title style={{fontSize: "1em"}}>Locations</Card.Title></Card.Header>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th>Active</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {facilityOptionList}
          </tbody>
        </Table>
      </Card>
    )
  }
}


const LinesOfBusinessModal = ({ show, onClose, practiceId, providerId, providerName, carrierId, practiceName, carrierName}) => {

  const[selectedBusinessLineId, setSelectedBusinessLineId] = useState(0);

  // Manage the facility state here so we can set back to loading when business line id changed
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const [activateAllRunning, setActivateAllRunning] = useState(false);

  const notyf = useContext(NotyfContext);

  useEffect(() => {
    if (practiceId > 0 && providerId > 0 && selectedBusinessLineId > 0)
    {
      authenticatedFetch({
        path: "businessline/practice/" + practiceId + "/provider/" + providerId + "/businessline/" + selectedBusinessLineId,
        successHandler: function(result) {
          setIsLoaded(true);
          setData(result);
        },
        errorHandler: function(error) {
          setIsLoaded(true);
          setError(error);
        }
      });
    }
    else
    {
      setIsLoaded(false);
      setData([]);
    }
  }, [practiceId, providerId, selectedBusinessLineId])

  const handleBusinessLineChanged = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setData([]);
    setSelectedBusinessLineId(e.target.value);
  }

  const handleClose = () => {
    setSelectedBusinessLineId(0);
    onClose();
  }
  
  const handleActivateAll = () => {
    if (!window.confirm("Activate any inactive business lines for this provider for *all* applicable locations?"))
    {
      return;
    }

    setActivateAllRunning(true);

    authenticatedFetch({
      method: "POST",
      path: "businessline/practice/" + practiceId + "/provider/" + providerId + "/carrier/" + carrierId,
      successHandler: function(result) {
        setActivateAllRunning(false);
        notyf.open({type: "success", message: "Activated " + result.count + " business lines!", duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
      },
      errorHandler: function(error) {
        setActivateAllRunning(false);
        notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
      }
    });
  }

  return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
          <Modal.Title>
            <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Lines of Business for {providerName}</span><br />{practiceName} | {carrierName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className="table-borderless table-sm"> 
            <tbody>
              <tr><td><BusinessLineSelect practiceId={practiceId} carrierId={carrierId} selectedBusinessLineId={selectedBusinessLineId} handleBusinessLineChanged={handleBusinessLineChanged} /></td></tr>
              {selectedBusinessLineId > 0 &&
              <tr><td><FacilityList isLoaded={isLoaded} error={error} data={data} practiceId={practiceId} providerId={providerId} businessLineId={selectedBusinessLineId}/></td></tr>
              }
            </tbody>
          </Table>
          {/* {data.updated && data.updatedBy ?
            <span className="float-end" style={{fontSize: "0.9em", fontStyle: "italic", marginRight: "5px"}}>Updated: {getActivityDateTimeDisplay(data.updated)} by {data.updatedBy}</span>
          : <></>
          } */}
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
          {selectedBusinessLineId == 0 && (activateAllRunning ? <Spinner className="me-auto" animation="border" size="sm" variant="primary" style={{marginLeft: '25px'}}/> : <Button className="me-auto" onClick={handleActivateAll} size="sm">Activate All</Button>)}
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
  );
}


export default LinesOfBusinessModal;


import { Button, Modal } from "react-bootstrap";


const ConfirmationModal = ({ show, title, subject, message, attachments, onCancel, onConfirm}) => {

  const openAttachment = (e, url) => {
    e.preventDefault();
    window.open(url);
  }

  let attachmentList = attachments && attachments.length > 0 && attachments.map((attachment, index) => {
    return <li key={index}><a href="#" onClick={(e) => openAttachment(e, attachment.url)}>{attachment.filename}</a></li>
  });

    return (
        <Modal show={show} onHide={onCancel}>
          <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
            <Modal.Title>
              <span style={{fontSize: "0.9em", fontWeight: "normal"}}>{title}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><b>Subject:</b>&nbsp;{subject}</p>
            <p><b>Message:</b></p>
            <p dangerouslySetInnerHTML={{ __html: message }} />
            {attachments && attachments.length > 0 &&
            <p><b>Attachments:</b>
              <ul>
                {attachmentList}
              </ul>
            </p>
            }
          </Modal.Body>
          <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
            <Button className="btn-secondary" onClick={onCancel}>Cancel</Button>
            <Button onClick={onConfirm}>OK</Button>
          </Modal.Footer>
        </Modal>
    );
  }
  
  
  export default ConfirmationModal;
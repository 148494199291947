import React, { useState, useContext, useEffect } from "react";
import { Button, Spinner, Modal, Table } from "react-bootstrap";
import NotyfContext from "../contexts/NotyfContext";

import authenticatedFetch from "../utils/fetch"
import getAvailableCarrierSubmissionActionTypeDisplay from "./autoActionTypes";
import ConfirmationModal from "./ConfirmationModal";


const TaskAdditionalActionsModal = ({ show, onClose, id, availableCarrierSubmissionActionType, reloadTaskLists}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isRunningCarrierSubmissionAction, setIsRunningCarrierSubmissionAction] = useState(false);
    const [previewSuject, setPreviewSubject] = useState("");
    const [previewMessage, setPreviewMessage] = useState("");
    const [previewAttachments, setPreviewAttachments] = useState([]);

    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const notyf = useContext(NotyfContext);

    const availableCarrierSubmissionActionTypeDisplay = getAvailableCarrierSubmissionActionTypeDisplay(availableCarrierSubmissionActionType);

    useEffect(() => {
      if (show)
      {
        authenticatedFetch({
          path: "task/carrierSubmissionAction/" + id,
          additionalHeaders: {"taskId": id},
          successHandler: function(result) {
            setIsLoaded(true);
            setPreviewSubject(result.content.subject);
            setPreviewMessage(result.content.message);
            setPreviewAttachments(result.attachments);
          },
          errorHandler: function(error) {
            notyf.open({type: "error", message: "Something went wrong; please reload the page", duration: 5000, dismissable: true, ripple: true});
          }
        });
      }
      else
      {
        setIsLoaded(false);
        setPreviewMessage("");
        setPreviewSubject("");
        setPreviewAttachments([]);
      }
    }, [id, show])

    const handleConfirmationModalConfirm = () => {
      setShowConfirmationModal(false);
      setIsRunningCarrierSubmissionAction(true);

      authenticatedFetch({
          path: "task/carrierSubmissionAction/" + id,
          method: "POST",
          additionalHeaders: {"taskId": id},
          successHandler: function() {
          var message = "Submitted!";
              if (availableCarrierSubmissionActionType === "EMAIL" || availableCarrierSubmissionActionType == "FAX")
              {
                  message = "Sent!";
              }

              reloadTaskLists();
              onClose();
              setIsRunningCarrierSubmissionAction(false);

              notyf.open({type: "success", message: message, duration: 5000, dismissable: true, ripple: true, background: '#3AAFA9'});
          },
          errorHandler: function() {
              notyf.open({type: "error", message: "Something went wrong; please try again", duration: 5000, dismissable: true, ripple: true});
              setIsRunningCarrierSubmissionAction(false);
          }
      });
    }

    const handleConfirmationModalCancel = () => {
      setShowConfirmationModal(false);
      setConfirmationTitle("");
    }


    const handleCarrierSubmissionActionClicked = (e) => {
        e.preventDefault();

        var title = "Are You Sure?"; // TODO others
        if (availableCarrierSubmissionActionType === "EMAIL")
        {
            title = "Send the Following Enrollment Email Request to the Carrier?";
        }

        setConfirmationTitle(title);
        setShowConfirmationModal(true);
    }
  
    const handleClose = () => {
      onClose();
    }
  
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton style={{backgroundColor: '#DEF2F1'}}>
            <Modal.Title>
              <span style={{fontSize: "0.9em", fontWeight: "normal"}}>Other Task Actions</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table className="table-sm" striped> 
              <tbody>
                <tr>
                    <td style={{width: "300px"}}>Carrier Submission</td>
                    <td>{isRunningCarrierSubmissionAction || !isLoaded ? 
                        <Spinner animation="border" size="sm" variant="primary" style={{marginLeft: "35px"}}/> : 
                        <Button onClick={(e) => handleCarrierSubmissionActionClicked(e)} variant="primary" size="sm">{availableCarrierSubmissionActionTypeDisplay}</Button>
                        }
                    </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer style={{backgroundColor: '#DEF2F1'}}>
            <Button onClick={handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
        <ConfirmationModal show={showConfirmationModal} title={confirmationTitle} message={previewMessage} subject={previewSuject} attachments={previewAttachments} onCancel={handleConfirmationModalCancel} onConfirm={handleConfirmationModalConfirm} />
      </>
    );
  }
  
  
  export default TaskAdditionalActionsModal;
import React, { useState, useContext } from "react";
import { Button, Card, Badge, ProgressBar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Paperclip } from "react-feather";
import { UserSettingsContext } from "../contexts/UserSettingsContext";
import doctor1 from "../assets/img/avatars/logo_unknown_doctor.jpg";

import TaskModal from "../utils/TaskModal";
import LastActivityDisplay from "../utils/LastActivityDisplay";
import rawTaskStatuses from "../utils/taskStatuses";
import TaskAdditionalActionsModal from "./TaskAdditionalActionsModal";
import getAvailableCarrierSubmissionActionTypeDisplay from "./autoActionTypes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRobot,
} from "@fortawesome/free-solid-svg-icons";

const Task = ({ reloadTaskLists, task, showModal=false, showDue=true, attachments=[], blocked=false, showProgress=true, hideProviderName=false, hidePracticeName=false, allowModal=true, shortLastActivity=false }) => {  
    const category = task.category.value;
    const badge = task.category.label;
  
    const assigneeDisplay = []; 
  
    const { isH3Admin, authorities } = useContext(UserSettingsContext);
    const canEdit = isH3Admin || authorities.includes("TASK_EDIT");
  
    const [show, setShow] = useState(showModal);
    const [showAdditionalActionsModal, setShowAdditionalActionsModal] = useState(false);
  
    if (showModal)
    {
      // Clear the task param... note that this will wipe any other url params too; and gets called every time the task is opened until the page is reloaded
      window.history.pushState({}, document.title, window.location.pathname);
    }
  
    const handleShow = () => setShow(true);
    const handleCancel = () => {
      setShow(false);
    }
  
    const handleSave = () => {
      setShow(false);
      reloadTaskLists();
    }
  
    const handleDelete = () => {
      setShow(false);
      reloadTaskLists();
    }

    const handleTaskCardClicked = (e) => {
        if (!allowModal) {
            // Assume coming from a read-only view (i.e. provider screen), take to tasks screen
            window.location = "/tasks?task=" + task.id;
        }
    }

    const handleShowAdditionalActions = (e) => { 
        e.preventDefault();
        setShowAdditionalActionsModal(true);
    }

    const handleAdditionalActionsModalClose = () => {
        setShowAdditionalActionsModal(false);
    }
  
    var avatarDisplay;
  
    // TODO decide if we also show practice assignee avatar here, and then either way need to flag them in the other spot too
    if (task.assignees && task.assignees.length > 0) {
      avatarDisplay = task.assignees.map((assignee, i) => {
        assigneeDisplay.push(assignee.firstName + ' ' + assignee.lastName);
        
        return (
          <img
            key={i}
            src={assignee.photoUrl == null || assignee.photoUrl === '' ? doctor1 : assignee.photoUrl}
            width="32"
            height="32"
            className="rounded-circle"
            alt={assignee.firstName + ' ' + assignee.lastName}
            title={assignee.firstName + ' ' + assignee.lastName}
          />
        )
      }, this);
    }
  
    var dueDateDisplay = '';
    var dueBg = 'primary';
    if (showDue && task.due != null)
    {
      var dueParts = task.due.split("-");
      var dueDate = new Date(dueParts[0], dueParts[1] - 1, dueParts[2]);
      dueDateDisplay = dueDate.toLocaleDateString("en-US");

      const diffInMs   = dueDate - new Date()
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      if (diffInDays <= -1)
      {
        dueBg = 'danger';
      }
      else if (diffInDays <= 4)
      {
        dueBg= 'warning';
      }
    }
  
    var statusDisplay = "";
    for (var i = 0; i < rawTaskStatuses.length; i++) {
      if (rawTaskStatuses[i].value === task.status) {
        statusDisplay = rawTaskStatuses[i].shortLabel;
        break;
      }
    }

    const availableCarrierSubmissionActionTypeDisplay = getAvailableCarrierSubmissionActionTypeDisplay(task.availableCarrierSubmissionActionType);
    const availableCarrierSubmissionActionTypeErrorsDisplay = task.availableCarrierSubmissionActionTypeErrors ? task.availableCarrierSubmissionActionTypeErrors.join("; ") : "";

    return (
      <Card style={{cursor: !allowModal ? "pointer" : "default"}} onClick={(e) => handleTaskCardClicked(e)} className="mb-3 bg-light border" id={task.id} data-ordering={task.ordering}>
        <Card.Header className="p-3 bg-light ">
        {task.status === "BLOCKED" || task.status === "BLOCKED_INTERNALLY" || task.status === "BLOCKED_INTERNALLY_PROVIDER" || task.status === "BLOCKED_SUBMITTED" ?
          <Badge pill className="float-start" bg={task.status === "BLOCKED_INTERNALLY"  || task.status === "BLOCKED_INTERNALLY_PROVIDER" || task.status === "BLOCKED_SUBMITTED" ? "warning" : "danger"}>
            {statusDisplay}
          </Badge> : ''
          }
         { showDue && task.due != null ?
          <>
            <Badge pill className="float-end" bg={dueBg}>{dueDateDisplay}</Badge><div style={{marginBottom: '8px', clear: 'right'}}></div>
          </>
          : <div style={{marginBottom: '8px', clear: "both"}}></div>
         }
          <Card.Title className="mb-0">
            {!hideProviderName && task.providerId !== 0 ? 
              (task.providerIsDeleted ? <>{task.providerFormattedName} <br /></>
              :
              <a href={"/provider/" + task.providerId} style={{color: "#495057"}}>{task.providerFormattedName}<br /></a>)
              
              : ""}

            <span style={{color: '#2B7A78', fontSize: '0.9em'}}>{task.carrierId !== 0 ? <>{task.carrierName}<br /></>: ""}</span>
            <span style={{color: '#2B7A78', fontSize: '0.9em'}}>{task.hubId !== 0 ? <>{task.hubName}<br /></>: ""}</span>
            <span style={{color: '#2B7A78', fontSize: '0.9em'}}>{task.facilityId !== 0 ? <>{task.facilityName}<br /></>: ""}</span>
            <span style={{color: '#6c757d', fontSize: '0.9em'}}>{task.other !== null && task.other !== "" ? <>{task.other}<br /></>: ""}</span>
            
            {!hidePracticeName ? <div style={{fontSize: "0.8em", fontWeight: "normal", width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}><a href={"/practice/" + task.practiceId} style={{color: "#495057"}}>{task.practiceName}</a></div>: ""}

            { badge != null ?
              <Badge pill className="float-start" bg="secondary" style={{marginTop: '2px'}}>
                {badge}
              </Badge> : ''
            }
          </Card.Title>
          
        </Card.Header>
        <Card.Body className="p-3" style={{marginTop: '-15px'}}>
          <hr style={{marginTop: '-10px'}} />
          <LastActivityDisplay task={task} shortLastActivity={shortLastActivity} />
          { attachments.length > 0 ?
          <p style={{cursor: 'pointer'}}><Paperclip style={{height: '15px', width: '15px'}} />&nbsp;{attachments.length} attachment{attachments.length > 1 ? 's' : ''}</p>
          : ''
          }
          { assigneeDisplay.length > 0 ?
          <p><b>Assigned to:</b>&nbsp;{assigneeDisplay.join(", ")}</p>
            : ''
          }
          <div className="float-end mt-n1">
            {avatarDisplay}
          </div>
          { allowModal ?
          <Button onClick={(e) => handleShow()} variant="primary" size="sm">
            {canEdit ? "Open" : "View Details"}
          </Button>
          : <></>
          }
          { allowModal && canEdit && availableCarrierSubmissionActionTypeDisplay !== "" ?
            <>&nbsp;&nbsp;&nbsp;<Button onClick={(e) => handleShowAdditionalActions(e)} variant="primary" size="sm"><FontAwesomeIcon icon={faRobot} style={{width: "16px", height: "16px"}}/></Button></>
            : (allowModal && canEdit && availableCarrierSubmissionActionTypeErrorsDisplay ? 
              <OverlayTrigger
                triggers="[hover,focus]"
                placement="bottom"
                overlay={<Tooltip>{availableCarrierSubmissionActionTypeErrorsDisplay}</Tooltip>}
                >
                  <span>&nbsp;&nbsp;<Button variant="warning" size="sm"><FontAwesomeIcon icon={faRobot} style={{width: "16px", height: "16px"}}/></Button></span>
              </OverlayTrigger>
            : <></>
            )
          }
          <br />
          {showProgress ? 
            <>
              <br />
              <p className="mb-2 fw-bold">
                Progress<span className="float-end">{task.progress}%</span>
              </p>
              <ProgressBar className="progress-sm" now={task.progress} />
            </>
               : ''
          }
        { allowModal ?
        <TaskModal show={show} onCancel={handleCancel} onSave={handleSave} onDelete={handleDelete} id={task.id} category={category} practiceId={task.practiceId} carrierId={task.carrierId} providerId={task.providerIsDeleted ? 0 : task.providerId} hubId={task.hubId} facilityId={task.facilityId} other={task.other} referenceNumber={task.referenceNumber} assignees={task.assignees} status={task.status} due={task.due} deadline={task.deadline} progress={task.progress} archived={task.archived} activityList={task.activity} providerIsDeleted={task.providerIsDeleted}/>
        : <></>
        }
        <TaskAdditionalActionsModal show={showAdditionalActionsModal} onClose={handleAdditionalActionsModalClose} id={task.id} availableCarrierSubmissionActionType={task.availableCarrierSubmissionActionType} reloadTaskLists={reloadTaskLists} />
        </Card.Body>
      </Card>
    )
  };

  export default Task;

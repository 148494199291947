import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Row, Table, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import {List, Grid} from "react-feather"

import useSidebar from "../hooks/useSidebar";
import useSettingsState from "../hooks/useSettingsState";

import { useTable, useSortBy, useFilters, useExpanded } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";

import { UserSettingsContext } from "../contexts/UserSettingsContext";

import TableMultiSelectColumnFilter from "../utils/TableMultiSelectColumnFilter";

import authenticatedFetch from "../utils/fetch"
import Task from "../utils/Task";
import TaskModal from "../utils/TaskModal";
import TaskBuilderModal from "../utils/TaskBuilderModal";
import TaskAssigneeSelect from "../utils/TaskAssigneeSelect";
import TaskPracticeSelect from "../utils/TaskPracticeSelect";
import EditableExpires from "../utils/EditableExpires";
import rawTaskStatuses from "../utils/taskStatuses";
import rawTaskCategories from "../utils/taskCategories";
import TaskListRow from "../utils/TaskListRow";
import TaskAdditionalActionsModal from "../utils/TaskAdditionalActionsModal";
import getActivityDateTimeDisplay from "../utils/datetime"
import getAvailableCarrierSubmissionActionTypeDisplay from "../utils/autoActionTypes";
import { getAssigneeAvatarDisplay } from "../utils/reactTableUtils";


const rawTaskStatusesWithShortLabels = rawTaskStatuses.map((rawTaskStatus) => {
  return {
    value: rawTaskStatus.value,
    label: rawTaskStatus.shortLabel
  }
});


const Lane = ({ name, status, className='', showAddNewTask=false, children, onContainerLoaded, reloadTaskLists }) => {
  const handleContainerLoaded = (container) => {
    if (container) {
      onContainerLoaded(container);
    }
  };

  const [show, setShow] = useState(false);

  const handleAddNewTask = () => setShow(true);
  const handleCancel = () => {
    setShow(false);
  }

  const handleSave = () => {
    setShow(false);
    reloadTaskLists();
  }

  return (

      <Card style={{minHeight: '200px'}}>
        <Card.Header>
          <Card.Title className={className}>{name}</Card.Title>
          {/* <h6 className="card-subtitle text-muted" >
            Nam pretium turpis et arcu. Duis arcu.
          </h6> */}
        </Card.Header>
        <Card.Body style={{marginTop: '-25px'}}>
          <div data-status={status} ref={handleContainerLoaded}>{children}</div>
          {showAddNewTask ? 
          <>
            <div className="d-grid">
              <Button variant="primary" onClick={(e) => handleAddNewTask()}>+ Add new task</Button>
            </div> 
            <TaskModal show={show} onCancel={handleCancel} onSave={handleSave} />
          </>
          : ''
          }
          {status === 'COMPLETE' ?
          <>
          (Completed in past 30 days)
          </>
          : <></>
          }
        </Card.Body>
      </Card>
  );
};

const getStatusRankValueString = (value, desc) => {
  var intRes = 1;
  if (value.toString() === "IN_PROGRESS") {
    intRes = 2;
  } else if (value.toString() === "BLOCKED") {
    intRes = 3;
  } else if (value.toString() === "BLOCKED_INTERNALLY") {
    intRes = 4;
  } else if (value.toString() === "BLOCKED_INTERNALLY_PROVIDER") {
    intRes = 5;
  } else if (value.toString() === "BLOCKED_SUBMITTED") {
    intRes = 6;
  } else if (value.toString() === "COMPLETE") {
    intRes = 7;
  }

  if (desc) {
    return (-1 * intRes).toString();
  }

  return intRes.toString();
};


const PracticeCell = ({ value, row }) => {

  const handlePracticeClicked = (e, practiceId) => {
    e.stopPropagation();
    window.location = "/practice/" + practiceId;
  }

  return (
    <a href="#" onClick={(e) => handlePracticeClicked(e, row.original.task.practiceId)}>{value}</a>
  )
};


const ProviderCell = ({ value, row }) => {

  const handleProviderClicked = (e, providerId) => {
    e.stopPropagation();
    window.location = "/provider/" + providerId;
  }

  if (row.original.task.providerIsDeleted) {
    return <>{value}</>
  }

  return (
    <a href="#" onClick={(e) => handleProviderClicked(e, row.original.task.providerId)}>{value}</a>
  )
};

const AdditionalActionsCell = ({ value, row, reloadTaskLists }) => {

  const [showAdditionalActionsModal, setShowAdditionalActionsModal] = useState(false);

  const { isH3Admin, authorities } = useContext(UserSettingsContext);
  const canEdit = isH3Admin || authorities.includes("TASK_EDIT");

  const handleShowAdditionalActions = (e) => { 
    setShowAdditionalActionsModal(true);
  }

  const handleAdditionalActionsModalClose = () => {
      setShowAdditionalActionsModal(false);
  }

  const availableCarrierSubmissionActionTypeDisplay = getAvailableCarrierSubmissionActionTypeDisplay(row.original.task.availableCarrierSubmissionActionType);
  const availableCarrierSubmissionActionTypeErrorsDisplay = row.original.task.availableCarrierSubmissionActionTypeErrors ? row.original.task.availableCarrierSubmissionActionTypeErrors.join("; ") : "";

  return (
    <>
      { canEdit && availableCarrierSubmissionActionTypeDisplay !== "" ?
        <>&nbsp;&nbsp;<Button onClick={(e) => handleShowAdditionalActions(e)} variant="primary" size="sm"><FontAwesomeIcon icon={faRobot} style={{width: "16px", height: "16px"}}/></Button></>
        :
        (availableCarrierSubmissionActionTypeErrorsDisplay ? 
          <OverlayTrigger
            triggers="[hover,focus]"
            placement="bottom"
            overlay={<Tooltip>{availableCarrierSubmissionActionTypeErrorsDisplay}</Tooltip>}
            >
              <span>&nbsp;&nbsp;<Button variant="warning" size="sm"><FontAwesomeIcon icon={faRobot} style={{width: "16px", height: "16px"}}/></Button></span>
          </OverlayTrigger>
        : <></>
        )
      }
      <TaskAdditionalActionsModal show={showAdditionalActionsModal} onClose={handleAdditionalActionsModalClose} id={row.original.task.id} availableCarrierSubmissionActionType={row.original.task.availableCarrierSubmissionActionType} reloadTaskLists={reloadTaskLists} />
    </>
  )
};

function StatusSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  return (
    <TableMultiSelectColumnFilter width="195px" filterValue={filterValue} setFilter={setFilter} rawCategories={rawTaskStatusesWithShortLabels} showNoCategory={false}/>
  );
}

function CategorySelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  return (
    <TableMultiSelectColumnFilter width="185px" filterValue={filterValue} setFilter={setFilter} rawCategories={rawTaskCategories} showNoCategory={false}/>
  );
}

function customStatusFilter(rows, columnIds, filterValues) {
  return rows.filter((row) => {
    for (var i = 0; i < filterValues.length; i++) {
      if (filterValues[i].value === row.original.task.status) {
        return true;
      }
    }
    
    return false;
  });
}

function customCategoryFilter(rows, columnIds, filterValues) {
  return rows.filter((row) => {
    for (var i = 0; i < filterValues.length; i++) {
      if (filterValues[i].value === row.original.task.category.value) {
        return true;
      }
    }
    
    return false;
  });
}

const TaskList = ({taskList, taskToOpen, isLoaded, error, reloadTaskLists}) => {
  const data = taskList;

  const columns = React.useMemo(
    () => [
      {
        Header: "Status",
        accessor: "task.status",
        key: "status",
        Filter: StatusSelectColumnFilter,
        filter: customStatusFilter,
        Cell: ({ value, row }) => {
          var statusLabel = "";
          for (var i = 0; i < rawTaskStatusesWithShortLabels.length; i++) {
            if (rawTaskStatusesWithShortLabels[i].value === value) {
              statusLabel = rawTaskStatusesWithShortLabels[i].label;
              break;
            }
          }
          if (value === "BLOCKED_INTERNALLY" || value == "BLOCKED_INTERNALLY_PROVIDER" || value === "BLOCKED_SUBMITTED")
          {
            return <span className="alert-warning">{statusLabel}</span>
          }
          else if (value === "BLOCKED")
          {
            return <span className="alert-danger">{statusLabel}</span>
          }

          return statusLabel;
        },
        sortType: (rowA, rowB, columnId, desc) => {
          return getStatusRankValueString(rowA.values[columnId], desc).localeCompare(getStatusRankValueString(rowB.values[columnId], desc));
        },
      },
      {
        Header: "Next Action",
        accessor: "task.due",
        key: "due",
        Cell: ({ value, row }) => (
          <EditableExpires localExpires={value} localActive={true} editingExpires={false} redDays={-1} yellowDays={4} warnWithColors={row.original.task.status !== "COMPLETE"} />
        )
      },
      {
        Header: "Deadline",
        accessor: "task.deadline",
        key: "deadline",
        Cell: ({ value, row }) => (
          <EditableExpires localExpires={value} localActive={true} editingExpires={false} redDays={-1} yellowDays={4} warnWithColors={row.original.task.status !== "COMPLETE"} />
        )
      },
      {
        Header: "Category",
        accessor: "task.category.label",
        key: "category",
        Filter: CategorySelectColumnFilter,
        filter: customCategoryFilter,
        Cell: ({ value, row }) => (
            <Badge pill className="float-start" bg="secondary">
              {value}
            </Badge>
        )
      },
      {
        Header: "Practice",
        accessor: "task.practiceName",
        key: "practiceName",
        Cell: ({ value, row }) => (
          <PracticeCell value={value} row={row}/>
        )
      },
      {
        Header: "Provider",
        accessor: "task.providerLastNameFirstName",
        key: "providerName",
        Cell: ({ value, row }) => (
          <ProviderCell value={value} row={row}/>
        )
      },
      {
        Header: "Carrier",
        accessor: "task.carrierName",
        key: "carrierName",
        Cell: ({ value, row }) => {
          return <span>{value}</span>
        }
      },
      {
        Header: "Data Hub",
        accessor: "task.hubName",
        key: "hubName",
        Cell: ({ value, row }) => {
          return <span>{value}</span>
        }
      },
      {
        Header: "Location",
        accessor: "task.facilityName",
        key: "facilityName",
        Cell: ({ value, row }) => {
          return <span>{value}</span>
        }
      },
      {
        Header: "Other",
        accessor: "task.other",
        key: "other",
        Cell: ({ value, row }) => {
          return <span>{value}</span>
        }
      },
      {
        Header: "Other Actions",
        accessor: "task.availableCarrierSubmissionActionType",
        key: "availableCarrierSubmissionActionType",
        Cell: ({ value, row }) => (
          <AdditionalActionsCell value={value} row={row} reloadTaskLists={reloadTaskLists}/>
        )
      },
      {
        Header: "Last Activity Date",
        accessor: item => item.task.activity.length > 0 ? item.task.activity[0].created : null,
        key: "lastActivityDate",
        Cell: ({ value, row }) => {
          return getActivityDateTimeDisplay(value, false, false, true);
        }
      },
      {
        Header: "Assignee(s)",
        accessor: "task.assignees",
        key: "assignees",
        Cell: ({ value, row }) => {
          return getAssigneeAvatarDisplay(value);
        }
      }
    ],
  []
  )

  var initialStatusFilter = [];
  for (var i = 0; i < rawTaskStatusesWithShortLabels.length; i++) {
    if (rawTaskStatusesWithShortLabels[i].value === "CREATED" || rawTaskStatusesWithShortLabels[i].value === "IN_PROGRESS" || 
    rawTaskStatusesWithShortLabels[i].value === "BLOCKED" || rawTaskStatusesWithShortLabels[i].value === "BLOCKED_INTERNALLY" ||
    rawTaskStatusesWithShortLabels[i].value === "BLOCKED_INTERNALLY_PROVIDER" || rawTaskStatusesWithShortLabels[i].value === "BLOCKED_SUBMITTED")
    {
      initialStatusFilter.push({"value": rawTaskStatusesWithShortLabels[i].value, "label": rawTaskStatusesWithShortLabels[i].label});
    } 
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: { 
        sortBy: [
          {
            id: 'task.due',
            desc: false
          }
        ], 
        filters: [
          {
              id: 'task.status',
              value: initialStatusFilter
          },
        ], 
      },
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
  );

  return (
    <Card>
        <Table className="table-sm" striped bordered hover {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restHeaderProps } = ( column.key === "assignees" || column.key === "category" || column.key === "status" || column.key === "category") ? column.getHeaderProps() : column.getHeaderProps(column.getSortByToggleProps());
                  return (
                    <th {...restHeaderProps} key={key}>
                      {column.render("Header")}
                      {(column.key !== "assignees" && column.key !== "category" && column.key !== "status") ? (
                        <>
                        {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                            ) : (
                              <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} className="ms-2" />
                        )}
                        </>
                      ) : <></>
                      }
                      {(column.key === "status" || column.key === "category") ? <>{column.render("Filter")}</>
                        : <></>
                      }
                    </th>
                  )
                }
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
            error ? (<tr><td colSpan={5}>Something went wrong; please reload the page...</td></tr>) : ( 
              !isLoaded ? (<tr><td colSpan={5}>Loading...</td></tr>) : (
              rows.map((row) => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();

                return (
                  <TaskListRow showModal={taskToOpen && taskToOpen === row.original.task.id} key={key} row={row} reloadTaskLists={reloadTaskLists}/>
                );
              })))
            }
            <tr>
              <td colSpan={12}>Total: <b>{rows.length}</b></td>
            </tr>
          </tbody>
        </Table>
    </Card>
  )
};



const containers = [];

const Tasks = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [taskToOpen, setTaskToOpen] = useState(null);

  const [filteredAssignees, setFilteredAssignees] = useState([]);
  const [filteredPractices, setFilteredPractices] = useState([]);
  const [viewType, setViewType] = useSettingsState('taskViewType', 'grid') // TODO local storage is shared for all users on the computer.. OK for short term, but need to change to have preferences like this stored on server

  const [show, setShow] = useState(false);
  const [showTaskBuilder, setShowTaskBuilder] = useState(false);

  const { isH3Admin, authorities } = useContext(UserSettingsContext);
  const canEdit = isH3Admin || authorities.includes("TASK_EDIT");

  const onContainerReady = (container) => {
    containers.push(container);
  };

  const { setBlockedBadge } = useSidebar();
  
  const [searchParams] = useSearchParams();
  
  if (searchParams.get("task") && searchParams.get("task") !== '')
  {
    var parsed = parseInt(searchParams.get("task"));
    if (parsed && !isNaN(parsed))
    {
      // Prevent infinite rendering loop
      setTimeout(() => {
        setTaskToOpen(parsed);
      }, 0);
      
    }
  }

  const handleAssigneesChanged = (assignees) => {
    setFilteredAssignees(assignees);
  }

  const handlePracticesChanged = (practices) => {
    setFilteredPractices(practices);
  }

  function isFiltered(task) 
  {
    return isFilteredByAssignee(task) || isFilteredByPractice(task);
  }

  function isFilteredByAssignee(task) 
  {
    if (!filteredAssignees || filteredAssignees.length === 0)
    {
      return false;
    }

    if (!task.assignees || task.assignees.length === 0)
    {
      return true;
    }

    for (var i = 0; i < task.assignees.length; i++)
    {
      for (var j=0; j < filteredAssignees.length; j++)
      {
        if (task.assignees[i].userId === filteredAssignees[j].value)
        {
          return false;
        }
      }
    }

    return true;
  }

  function isFilteredByPractice(task) 
  {
    if (!filteredPractices || filteredPractices.length === 0)
    {
      return false;
    }

    if (!task.practiceId || task.practiceId === 0)
    {
      return true;
    }

    for (var j=0; j < filteredPractices.length; j++)
    {
      if (task.practiceId === filteredPractices[j].value)
      {
        return false;
      }
    }
  
    return true;
  }


  const handleAddNewTask = () => setShow(true);
  const handleTaskBuilder = () => setShowTaskBuilder(true);

  const handleCancel = () => {
    setShow(false);
  }
  
  const handleCancelTaskBuilder = () => {
    setShowTaskBuilder(false);
  }

  const handleSave = () => {
    setShow(false);
    reloadTaskLists();
  }

  const handleTaskBuilderSave = () => {
    setShowTaskBuilder(false);
    reloadTaskLists();
  }

  const handleViewTypeChanged = (viewType) => {
    setTaskToOpen(null);
    setViewType(viewType);
  }

  useEffect(() => {
    authenticatedFetch({
      path: "task/revenueteam",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });

    authenticatedFetch({
      path: "task/blocked",
      successHandler: function(result) {
        setBlockedBadge(result)
      }
    });
  }, [setBlockedBadge]);

  const reloadTaskLists = () =>
  {
    authenticatedFetch({
      path: "task/revenueteam",
      successHandler: function(result) {
        setIsLoaded(true);
        setData(result);
      },
      errorHandler: function(error) {
        setIsLoaded(true);
        setError(error);
      }
    });

    authenticatedFetch({
      path: "task/blocked",
      successHandler: function(result) {
        setBlockedBadge(result)
      }
    });
  }

  if (!isLoaded) {
    return (
    <React.Fragment>
        <Helmet title="Tasks" />
        <h1 className="h3 mb-3">Tasks</h1>
        <div>Loading...</div>
      </React.Fragment>
    );
  }
  else if (error || !data) {
    return (
      <React.Fragment>
          <Helmet title="Tasks" />
          <h1 className="h3 mb-3">Tasks</h1>
          <div>Something went wrong... please reload the page...</div>
        </React.Fragment>
    );
  }
  else {
    var showArchived=false;
    
    const tasksNotStarted = data.filter(function(task) {
      if (task.status === 'CREATED' && (showArchived || !task.archived)) {
        if (isFiltered(task))
        {
          return false;
        }

        return true;
      }
      return false;
    }).map((task, i) => {
      return (
        <Task
          key={task.id}
          task={task}
          reloadTaskLists={reloadTaskLists}
          showProgress={false}
          showModal={taskToOpen && taskToOpen === task.id}
          //attachments = {[1,2]}
        />
      )
    }, this);

    const tasksInProgress = data.filter(function(task) {
      if (task.status === 'IN_PROGRESS' && (showArchived || !task.archived)) {
        if (isFiltered(task))
        {
          return false;
        }

        return true;
      }
      return false;
    }).map((task, i) => {
      return (
        <Task
          key={task.id}
          task={task}
          reloadTaskLists={reloadTaskLists}
          showModal={taskToOpen && taskToOpen === task.id}
          //attachments = {[1,2]}
        />
      )
    }, this);

    const tasksBlocked = data.filter(function(task) {
      if ((task.status === 'BLOCKED' || task.status === 'BLOCKED_INTERNALLY' || task.status === 'BLOCKED_INTERNALLY_PROVIDER' || task.status === 'BLOCKED_SUBMITTED') && (showArchived || !task.archived)) {
        if (isFiltered(task))
        {
          return false;
        }

        return true;
      }
      return false;
    }).map((task, i) => {
      return (
        <Task
          key={task.id}
          task={task}
          reloadTaskLists={reloadTaskLists}
          showModal={taskToOpen && taskToOpen === task.id}
          //attachments = {[1,2]}
        />
      )
    }, this);

    const tasksComplete = data.filter(function(task) {
      if (task.status === 'COMPLETE' && (showArchived || !task.archived)) {
        if (isFiltered(task))
        {
          return false;
        }

        return true;
      }
      return false;
    }).sort(function(taskA, taskB) {
      // We don't have a true completed date field, but sort completed tasks by updated date DESC
      if (taskA.updated < taskB.updated)
      {
        return 1;
      }
      if (taskA.updated > taskB.updated)
      {
        return -1;
      }

      return 0;
    }).map((task, i) => {
      return (
        <Task
          key={task.id}
          task={task}
          reloadTaskLists={reloadTaskLists}
          showProgress={false}
          showDue={false}
          showModal={taskToOpen && taskToOpen === task.id}
          //attachments = {[1,2]}
        />
      )
    }, this);

    var taskList = [];
    if (isLoaded)
    {
      taskList = data.filter(function(task) {
        if (showArchived || !task.archived) {
          if (isFiltered(task))
          {
            return false;
          }
  
          return true;
        }
        return false;
      }).map((task, i) => {
        return {task: task}
      }, this);
    }
  
    return (
      <React.Fragment>
        <Helmet title="Tasks" />
        <Container fluid className="p-0" style={{marginTop: "-20px"}}>
          {/* <Button variant="primary" className="float-end mt-n1">
            <FontAwesomeIcon icon={faPlus} /> New task
          </Button> */}
          <Row style={{marginBottom: "-10px"}}>
            <Col lg="3">
              <h1 className="h3 mb-3">Tasks</h1>
            </Col>
            <Col lg="4" style={{marginTop: "-5px"}}>
              <TaskAssigneeSelect handleAssigneesChanged={handleAssigneesChanged} selectedAssignees={filteredAssignees} placeholder="Filter by assignee(s)..." size="sm"/>
            </Col>
            <Col lg="4" style={{marginTop: "-5px"}}>
              <TaskPracticeSelect handlePracticesChanged={handlePracticesChanged} selectedPractices={filteredPractices} size="sm"/>
            </Col>
            <Col lg="1" style={{marginTop: window.innerWidth < 1300 ? '10px' : ''}}>
              <Grid className={viewType === "grid" ? "text-success" : ""} style={{cursor: viewType === "grid" ? "default" : "pointer"}} onClick={(e) => handleViewTypeChanged('grid')}/>
              &nbsp;
              <List className={viewType === "list" ? "text-success" : ""} style={{cursor: viewType === "list" ? "default" : "pointer"}} onClick={(e) => handleViewTypeChanged('list')}/>
            </Col>
          </Row>
          <Row style={{marginBottom: "20px"}}>
            {canEdit &&
            <Col style={{marginTop: window.innerWidth < 1300 ? '20px' : ''}}>
              <Button variant="primary" size="sm" onClick={(e) => handleAddNewTask()}>+ Add new task</Button>
              <TaskModal show={show} onCancel={handleCancel} onSave={handleSave} />             
              &nbsp;&nbsp;
              <Button variant="primary" size="sm" onClick={(e) => handleTaskBuilder()}>Task Builder</Button>
              <TaskBuilderModal show={showTaskBuilder} onHide={handleCancelTaskBuilder} onSave={handleTaskBuilderSave} />
            </Col>
            }
          </Row>
          {viewType === "grid" ?
          <Row>
            <Col sm="6" md="3">
              <Lane name="Inbox" status="CREATED" onContainerLoaded={onContainerReady} reloadTaskLists={reloadTaskLists} showAddNewTask={canEdit}>
                {tasksNotStarted}
              </Lane>
            </Col>
            <Col sm="6" md="3">
              <Lane name="In Progress"  status="IN_PROGRESS" onContainerLoaded={onContainerReady}>
                {tasksInProgress}
              </Lane>
            </Col>
            <Col sm="6" md="3">
              <Lane name="Waiting" status="BLOCKED" onContainerLoaded={onContainerReady} className="alert alert-danger">
                {tasksBlocked}
              </Lane>
            </Col>
            <Col sm="6" md="3">
              <Lane name="Complete" status="COMPLETE" onContainerLoaded={onContainerReady}>
                {tasksComplete}
              </Lane>
            </Col>
          </Row>
          : <>
              <TaskList taskList={taskList} taskToOpen={taskToOpen} isLoaded={isLoaded} error={error} reloadTaskLists={reloadTaskLists} />
            </>
          }
        </Container>
      </React.Fragment>
    );
  }
};

export default Tasks;
